import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import OtherPageHeader from "../components/otherPageHeader.js";
import { Container, Row } from "reactstrap";

const WelcomePage = () => (
  <Layout pageTitle="Welcome">
    <OtherPageHeader />
    <div className="main">
      <div className="section text-center">
        <Container>
          <Row>
            <h1>Welcome message from the president</h1>
          </Row>
        </Container>
      </div>
    </div>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>##test test test##</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
    <p>.</p>
  </Layout>
)

export default WelcomePage
